import React, { Component } from 'react';
import './loading-indicator.css';

export default class LoadingIndicator extends Component {
  constructor(props) {
    super(props);
    const { heightDiv = '20rem', heightSpinner = '50%' } = props;

    this.state = {
      isLoading: false,
      heightDiv,
      heightSpinner,
    };
  }

  show = () => {
    this.setState({isFutureLoading: true});
    setTimeout(() => {
      this.setState({ isLoading: this.state.isFutureLoading });
    }, 50); 
  };

  hide = () => {
    this.setState({ isLoading: false, isFutureLoading: false });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="loading-spinner-div" style={{height: this.state.heightDiv}}>
          <img src={require('./loading.gif')} className="loading-spinner" style={{height: this.state.heightSpinner}} alt="Loading"/>
        </div>);
    } else {
      return null;
    }
  }
}