var realTeamAbbrevs = [
    "ARZ",
    "ATL",
    "BLT",
    "BUF",
    "CAR",
    "CHI",
    "CIN",
    "CLV",
    "DAL",
    "DEN",
    "DET",
    "GB",
    "HST",
    "IND",
    "JAX",
    "KC",
    "LAC",
    "LAR",
    "LV",
    "MIA",
    "MIN",
    "NE",
    "NO",
    "NYG",
    "NYJ",
    "PHI",
    "PIT",
    "SEA",
    "SF",
    "TB",
    "TEN",
    "WAS"
]

var realTeamAbbrevsDivision = [
    "BUF",
    "NYJ",
    "MIA",
    "NE",
    "BLT",
    "CIN",
    "CLV",
    "PIT",
    "IND",
    "TEN",
    "JAX",
    "HST",
    "KC",
    "LAC",
    "DEN",
    "LV",
    "PHI",
    "NYG",
    "DAL",
    "WAS",
    "MIN",
    "GB",
    "CHI",
    "DET",
    "ATL",
    "TB",
    "NO",
    "CAR",
    "ARZ",
    "LAR",
    "SF",
    "SEA"
]

var displayTeamAbbrevs = [
    "ARI",
    "ATL",
    "BAL",
    "BUF",
    "CAR",
    "CHI",
    "CIN",
    "CLE",
    "DAL",
    "DEN",
    "DET",
    "GB",
    "HOU",
    "IND",
    "JAX",
    "KC",
    "LAC",
    "LAR",
    "LV",
    "MIA",
    "MIN",
    "NE",
    "NO",
    "NYG",
    "NYJ",
    "PHI",
    "PIT",
    "SEA",
    "SF",
    "TB",
    "TEN",
    "WAS"
]

var teamLocations = [
    "Arizona",
    "Atlanta",
    "Baltimore",
    "Buffalo",
    "Carolina",
    "Chicago",
    "Cincinnati",
    "Cleveland",
    "Dallas",
    "Denver",
    "Detroit",
    "Green Bay",
    "Houston",
    "Indianapolis",
    "Jacksonville",
    "Kansas City",
    "Los Angeles",
    "Los Angeles",
    "Las Vegas",
    "Miami",
    "Minnesota",
    "New England",
    "New Orleans",
    "New York",
    "New York",
    "Philadelphia",
    "Pittsburgh",
    "Seattle",
    "San Francisco",
    "Tampa Bay",
    "Tennessee",
    "Washington"
]

var teamNames = [
    "Cardinals",
    "Falcons",
    "Ravens",
    "Bills",
    "Panthers",
    "Bears",
    "Bengals",
    "Browns",
    "Cowboys",
    "Broncos",
    "Lions",
    "Packers",
    "Texans",
    "Colts",
    "Jaguars",
    "Chiefs",
    "Chargers",
    "Rams",
    "Raiders",
    "Dolphins",
    "Vikings",
    "Patriots",
    "Saints",
    "Giants",
    "Jets",
    "Eagles",
    "Steelers",
    "Seahawks",
    "49ers",
    "Buccaneers",
    "Titans",
    "Commanders"
]

class TeamsManager {
    getTeams() {
        return realTeamAbbrevs;
    }
    
    getDivisionSortedTeams() {
        return realTeamAbbrevsDivision;
    }
    
    getDisplayTeams() {
        return displayTeamAbbrevs;
    }
    
    getTeamDisplayAbbrev(team) {
        if (team.length < 1) return team;
        var lower = team[0] === team[0].toLowerCase();
        var upperTeam = team.toUpperCase();
        var index = realTeamAbbrevs.indexOf(upperTeam);
        if (index === -1) return team;
        var returnTeam = displayTeamAbbrevs[index];
        if (lower) returnTeam = returnTeam.toLowerCase();
        return returnTeam;
    }
    
    getTeamRealAbbrev(team) {
        if (team.length < 1) return team;
        var lower = team[0] === team[0].toLowerCase();
        var upperTeam = team.toUpperCase();
        var index = displayTeamAbbrevs.indexOf(upperTeam);
        if (index === -1) return team;
        var returnTeam = realTeamAbbrevs[index];
        if (lower) returnTeam = returnTeam.toLowerCase();
        return returnTeam;
    }
    
    getTeamLocation(teamAbbrev) {
        if (teamAbbrev.length < 1) return "unknown";
        var upperTeam = teamAbbrev.toUpperCase();
        var index = realTeamAbbrevs.indexOf(upperTeam);
        if (index === -1) return "unknown";;
        var returnTeam = teamLocations[index];
        return returnTeam;
    }
    
    getTeamName(teamAbbrev) {
        if (teamAbbrev.length < 1) return "unknown";
        var upperTeam = teamAbbrev.toUpperCase();
        var index = realTeamAbbrevs.indexOf(upperTeam);
        if (index === -1) return "unknown";;
        var returnTeam = teamNames[index];
        return returnTeam;
    }
}

const teamsManager = new TeamsManager();
export default teamsManager;
