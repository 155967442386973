import React from 'react';
import { useParams } from "react-router-dom";
import { attachEvent } from '../../../helpers/database.js';
import seasonsManager from '../../../helpers/seasons.js';
import cacheManager from '../../../helpers/cacher.js';
import { reattachIfFailed } from '../../../helpers/reattacher.js';
import { getTimeString } from '../../../helpers/time.js';
import UpcomingGame from '../../molecules/upcoming-game/upcoming-game.js';
import "./upcoming-games.css";
import LoadingIndicator from '../../atoms/loading-indicator/loading-indicator.js'

class UpcomingGames extends React.Component {
    constructor(props) {
        super(props);
        var seasons = seasonsManager.getSeasons();
        this.loadingRef = React.createRef();
        this.upcomingGameRefs = [];
        for (var i = 0; i < 16; i++) {
            this.upcomingGameRefs.push(React.createRef());
        }

        var games = cacheManager.getUpcomingGames();
        var customMode = false;
        if (props.params.id) {
            customMode = true;
            if (props.params.id >= 0 && props.params.id < games.length) {
                games = [games[props.params.id]];
            }
        }

        var season = seasons[seasons.length - 1];
        if (games && games.Games && games.Games.length > 0) {
            var game = games.Games[0];
            var activeSeason = (new Date(game.StartTime)).getFullYear();
            if (season == activeSeason && games.WeekName == "Week 1") {
                // We don't want upcoming games for week 1 of regular season to start using analytics from the current season
                season--;
            }
        }

        seasonsManager.onSeasonsUpdated(this.updateSeason.bind(this));

        reattachIfFailed(this);

        this.state = {
            upcomingGames: games,
            season: season,
            customMode: customMode
        };
    }

    render() {
        var radars = [];
        var weekName = "";
        var upcomingGameDiv = "outerUpcomingGamesDiv prevent-select";
        if (this.state.customMode) upcomingGameDiv += " customMode";
        if (this.state.upcomingGames && this.state.upcomingGames.Games) {
            weekName = this.state.upcomingGames.WeekName;
            for (var i = 0; i < this.state.upcomingGames.Games.length; i++) {
                var upcomingGame = this.state.upcomingGames.Games[i];
                var awayTeam = upcomingGame.AwayTeam.toLowerCase();
                var homeTeam = upcomingGame.HomeTeam.toLowerCase();
                var timeString = getTimeString(upcomingGame.StartTime, true);
                var awayTeamRecord = "";
                var homeTeamRecord = "";

                // Only populate home and away records if the data is complete
                if (upcomingGame.AwayTeamWins !== undefined &&
                    upcomingGame.AwayTeamLosses !== undefined &&
                    upcomingGame.AwayTeamTies !== undefined &&
                    upcomingGame.HomeTeamWins !== undefined &&
                    upcomingGame.HomeTeamLosses !== undefined &&
                    upcomingGame.HomeTeamTies !== undefined) {
                    awayTeamRecord = upcomingGame.AwayTeamTies === 0 ? `${upcomingGame.AwayTeamWins} - ${upcomingGame.AwayTeamLosses}` : `${upcomingGame.AwayTeamWins} - ${upcomingGame.AwayTeamTies} - ${upcomingGame.AwayTeamLosses}`;
                    homeTeamRecord = upcomingGame.HomeTeamTies === 0 ? `${upcomingGame.HomeTeamWins} - ${upcomingGame.HomeTeamLosses}` : `${upcomingGame.HomeTeamWins} - ${upcomingGame.HomeTeamTies} - ${upcomingGame.HomeTeamLosses}`;
                }

                radars.push(
                    <div key={i} className={upcomingGameDiv}>
                        <UpcomingGame
                            ref={this.upcomingGameRefs[i]}
                            awayTeam={awayTeam}
                            homeTeam={homeTeam}
                            timeString={timeString}
                            season={this.state.season}
                            homeTeamRecord = {homeTeamRecord}
                            awayTeamRecord = {awayTeamRecord}
                            />
                    </div>
                );
            }
        }

        return (
            <div>
                <div className="upcoming-games-title">Upcoming Games</div>
                <div className="upcoming-games-week">{weekName}</div>
                <div className="upcoming-games-radars">
                    <LoadingIndicator ref={this.loadingRef} heightDiv='10rem' heightSpinner='100%' />
                    {radars}
                </div>
            </div>
        );
    }

    updateSeason() {
        var seasons = seasonsManager.getSeasons();
        var season = seasons[seasons.length - 1];
        if (this.state && this.state.upcomingGames && this.state.upcomingGames.Games && this.state.upcomingGames.Games.length > 0) {
            var game = this.state.upcomingGames.Games[0];
            var activeSeason = (new Date(game.StartTime)).getFullYear();
            if (season == activeSeason && this.state.upcomingGames.WeekName == "Week 1") {
                // We don't want upcoming games for week 1 of regular season to start using analytics from the current season
                season--;
            }
        }

        this.setState({season: season});
    }

    attachToDB() {
        if (!this.state.upcomingGames || !this.state.upcomingGames.Games) {
            this.loadingRef.current?.show();
        }

        this.state.detach = attachEvent('UpcomingGames', (upcomingGames) => {
            cacheManager.saveUpcomingGames(upcomingGames);
            if (this.props.params.id) {
                if (this.props.params.id >= 0 && this.props.params.id < upcomingGames.Games.length) {
                    upcomingGames.Games = [upcomingGames.Games[this.props.params.id]];
                }
            }

            this.setState({upcomingGames: upcomingGames, dbHit: true});
            this.updateSeason();
            for (var i = 0; i < this.upcomingGameRefs.length && i < upcomingGames.Games.length; i++) {
                if (this.upcomingGameRefs[i].current) {
                    var upcomingGame = upcomingGames.Games[i];
                    var awayTeam = upcomingGame.AwayTeam;
                    var homeTeam = upcomingGame.HomeTeam;
                    var timeString = getTimeString(upcomingGame.StartTime, true);
                    this.upcomingGameRefs[i].current.update(
                        awayTeam,
                        homeTeam,
                        this.state.season,
                        "Season",
                        timeString);
                }
            }
            this.loadingRef.current?.hide();
        });
    }

    detachFromDB() {
        if (this.state.detach) {
            this.state.detach();
            this.state.detach = null;
        }
    }

    componentDidMount() {
        this.attachToDB();
    }

    componentWillUnmount() {
        this.detachFromDB();
    }
}

export default function UpcomingGamesComponentWrapper(props) {
    const params = useParams();
    return <UpcomingGames {...props} params={params}/>;
};