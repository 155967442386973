import React, { Component } from 'react';
import { Card } from '@mui/material';
import { Link } from "react-router-dom";
import colorManager from '../../../helpers/colors.js';
import logoManager from '../../../helpers/logos.js';
import './standings-team-tile.css';

export default class StandingsTeamTile extends Component {
    render() {
        const colors = colorManager.getTeamColors(this.props.teamAbbrev)
        var teamAbbrev = this.props.teamAbbrev.toLowerCase();
        const img = logoManager.getLogo(teamAbbrev);
        var scoreStr = this.props.wins + " - " + this.props.losses;
        if (this.props.ties > 0) {
            scoreStr += " - " + this.props.ties;
        }

        return (
            <Link to={"/analytics/categories/" + teamAbbrev}>
                <Card sx={[
                    {'&': {height: "3.2em", backgroundColor: colors.Secondary, marginTop: "1.3rem", boxShadow: "0 8px 40px -12px rgba(0,0,0,1)", transition: "box-shadow 0.1s ease-in-out"}},
                    {'&:hover': {boxShadow: "0 8px 40px -4px rgba(0,0,0,1)"}}
                    ]}>
                    <Card className="card" sx={{ height: "3em", backgroundColor: colors.Primary }}>
                        <div className="team-logo-div">
                            <img src={img} alt="" className="team-logo"/>
                        </div>
                        <div className="location-name">
                            <h1 className="location"> {this.props.location} </h1>
                            <h1 className="name"> {this.props.name} </h1>
                        </div>
                        <h1 className="score">{scoreStr}</h1>
                    </Card>
                </Card>
            </Link>
        );
    }
}
