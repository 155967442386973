import { attachEvent } from '../../../helpers/database.js';
import { getTimeString } from '../../../helpers/time.js';
import cacheManager from '../../../helpers/cacher.js';
import React from "react";
import './time-last-updated.css';

export default class TimeLastUpdated extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeLastUpdated: cacheManager.getTimeLastUpdated()
        }
    }

    attachToDB() {
        this.state.detach = attachEvent('UpdateTimes/ScrapeTime', (rawTime) => {
            var timeLastUpdated = getTimeString(rawTime, false);
            cacheManager.saveTimeLastUpdated(timeLastUpdated);
            this.setState({timeLastUpdated: timeLastUpdated});
        });
    }

    detachFromDB() {
        if (this.state.detach) {
            this.state.detach();
            this.state.detach = null;
        }
    }

    render() {
        return (
            <div className="time-last-updated">
                <p>{this.state.timeLastUpdated}</p>
            </div>
        );
    }

    componentDidMount() {
        this.attachToDB();
    }

    componentWillUnmount() {
        this.detachFromDB();
    }
}
