import React from "react";
import { Card } from "@mui/material";
import StreakTeamTile from '../../atoms/streak-team-tile/streak-team-tile.js';
import "./streak-card.css";

export default function StreakCard(props) {
    var rankings = [];
    for (var i = 0; i < props.streakEntries.length; i++) {
        var streakEntry = props.streakEntries[i];
        rankings.push(
            <div key={i} className="streak-card-team-tile-div">
                <StreakTeamTile name={streakEntry.Name} streakString={streakEntry.StreakString}/>
            </div>
        );
    }

    return (
        <Card
            className="streak-card-container prevent-select"
            sx={[
                {'&': {boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)", transition: "box-shadow 300ms"}}
            ]}>
            <h2 className="streak-card-name">{props.streakName}</h2>
            <div>
                {rankings}
            </div>
        </Card>
    );
}
