export function reattachIfFailed(object) {
    // This is here in case after 3 seconds, if our event still hasn't been hit, dispose it and recreated it.
    // This handles the strange firebase issue where the first connection sometimes hangs.
    setTimeout(
        function() {
            if (!object.state.dbHit) {
                object.detachFromDB();
                object.attachToDB();
            }
        }, 3000);
}
