import React from 'react';
import { Link } from "react-router-dom";
import colorManager from '../../../helpers/colors.js';
import logoManager from '../../../helpers/logos.js';
import TeamRadar from '../../atoms/team-radar/team-radar.js';
import "./upcoming-game.css";

export default class UpcomingGame extends React.Component {
    constructor(props) {
        super(props);
        this.radarRef = React.createRef();
        this.state = {
            awayTeam: props.awayTeam,
            homeTeam: props.homeTeam,
            timeString: props.timeString,
            season: props.season,
        };
    }

    update = (team1, team2, season, mode, timeString) => {
        this.setState({awayTeam: team1.toLowerCase(), homeTeam: team2.toLowerCase(), timeString: timeString});
        if (this.radarRef && this.radarRef.current) {
            this.radarRef.current.update([team1, team2], season, mode);
        }
    }

    render() {
        var awayTeamImage = logoManager.getLogo(this.state.awayTeam);
        var homeTeamImage = logoManager.getLogo(this.state.homeTeam);
        var selectedTeamsLink = "/analytics/categories/" + this.state.awayTeam + "-" + this.state.homeTeam;
        return (
            <Link to={selectedTeamsLink}>
                <div className="upcoming-game-time">{this.state.timeString.toString().toUpperCase()}</div>
                <div className="upcoming-game-div">
                    <div className="upcoming-game-half" style={{ borderColor: colorManager.getContrastColor(this.state.awayTeam) }}>
                        <div className="away-div">
                            <img src={awayTeamImage} alt=""/>
                            <h1>{this.props.awayTeamRecord}</h1>
                        </div>
                    </div>

                    <div className="upcoming-game-vs-radar-div">
                        <TeamRadar
                            ref={this.radarRef}
                            contrastToggle={true}
                            teams={[this.state.awayTeam, this.state.homeTeam]}
                            radarModes={[0]}
                            season={this.state.season}
                            mode="Season"
                            spacing={true} />
                    </div>

                    <div className="upcoming-game-half" style={{ backgroundColor: colorManager.getContrastColor(this.state.homeTeam), borderColor: colorManager.getContrastColor(this.state.homeTeam) }}>
                        <div className='home-div'>
                            <img src={homeTeamImage} alt=""/>
                            <h1>{this.props.homeTeamRecord}</h1>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}
