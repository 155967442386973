import VersionNumber from '../../atoms/version-number/version-number.js';
import TimeLastUpdated from '../../atoms/time-last-updated/time-last-updated.js';
import './footer.css';

export default function Footer(props) {
    return (
        <div className="footer-div">
            <VersionNumber />
            <TimeLastUpdated />
        </div>
    );
}
