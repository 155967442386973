import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInAnonymously } from 'firebase/auth';
import ReactGA from "react-ga4";

var live = true;

const liveFirebaseConfig = {
    apiKey: "AIzaSyDRqNYW_ob2iEMBhhnZSryJEhjGa9ZPXz0",
    authDomain: "nfl-analytics-d698b.firebaseapp.com",
    databaseURL: "https://nfl-analytics-d698b-default-rtdb.firebaseio.com",
    projectId: "nfl-analytics-d698b",
    storageBucket: "nfl-analytics-d698b.appspot.com",
    messagingSenderId: "282288183024",
    appId: "1:282288183024:web:c06f9038ca5bb283259435",
    measurementId: "G-BPHND2ZCF6"
};

const testFirebaseConfig = {
    apiKey: "AIzaSyAjTSmpUhY5YmWH-D0HNlKwtREiAxxavt4",
    authDomain: "nfl-stat-uni---test.firebaseapp.com",
    databaseURL: "https://nfl-stat-uni---test-default-rtdb.firebaseio.com",
    projectId: "nfl-stat-uni---test",
    storageBucket: "nfl-stat-uni---test.appspot.com",
    messagingSenderId: "713603061545",
    appId: "1:713603061545:web:b0bd4231bfc64707850938",
    measurementId: "G-81679SG4FE"
};

const firebaseApp = initializeApp(live ? liveFirebaseConfig : testFirebaseConfig, live ? "live" : "test");

const auth = getAuth(firebaseApp);
signInAnonymously(auth)
.then(() => {
    console.log("Signed in anonymously");
})
.catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Error signing in anonymously: ", errorCode, errorMessage);
});

const db = getDatabase(firebaseApp);
const analytics = getAnalytics(firebaseApp);                    // Firebase analytics
ReactGA.initialize(live ? "G-BPHND2ZCF6" : "G-81679SG4FE");     // Google GA analytics

export function isTestMode() {
    return !live;
}

export function attachEvent(key, handler) {
    var dbRef = ref(db, key);
    return onValue(dbRef, (snapshot) => {
        if (snapshot.exists()) {
            handler(snapshot.val());
        }
    });
}

export function publishPageAnalytics() {
    var location = window.location.pathname + window.location.search;
    ReactGA.event({
        category: location,
        action: location,
        label: location,
    });
}

export function publishCustomEvent(eventName) {
    ReactGA.event({
        category: "custom_event",
        action: `custom_${eventName}_action`,
        label: `custom_${eventName}_label`,
    });
}
