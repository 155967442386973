import { attachEvent } from './database.js';

class TagsManager {
    constructor() {
        this.tags = ["All"];
        this.callbacks = [];

        attachEvent('Analytics/Tags', (temp) => {
            if (temp.length > 0) {
                this.tags = temp;
                for (var i = 0; i < this.callbacks.length; i++) {
                    this.callbacks[i]();
                }
            }
        });

    }
    
    getTags() {
        return this.tags;
    }
    
    onTagsUpdated(callback) {
        this.callbacks.push(callback);
    }
    
}

const tagsManager = new TagsManager();
export default tagsManager;

