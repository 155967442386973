import React from 'react';
import { Card } from "@mui/material";
import cacheManager from '../../../helpers/cacher.js';
import { attachEvent } from '../../../helpers/database.js';
import { reattachIfFailed } from '../../../helpers/reattacher.js';
import StandingsTeamTile from '../../atoms/standings-team-tile/standings-team-tile.js';
import './standings-card.css';

export default class StandingsCard extends React.Component {
    constructor(props) {
        super(props);
        var standings = cacheManager.getStandings(props.conference, props.division);

        reattachIfFailed(this);

        this.state = {
            conference: props.conference,
            division: props.division,
            standings: standings
        }
    }

    render() {
        var divisonString = "LEAGUE";
        if (this.state.conference) {
            divisonString = this.state.conference.toUpperCase();
            if (this.state.division) {
                var locStr = this.state.division;
                locStr = locStr.charAt(0).toUpperCase() + locStr.slice(1);
                divisonString += " " + locStr.toUpperCase();
            }
        }
        return (
            <div className="listOfTeams prevent-select">
                <Card
                    className="standingsContainer"
                    sx={[
                        {'&': {boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)", transition: "box-shadow 300ms"}}
                    ]}>
                    <h2 className="divisionName">{divisonString}</h2>
                    <div>
                        {Object.entries(this.state.standings).map(([key, { TeamLocation, TeamName, TeamAbbrev, Wins, Losses, Ties }]) => (
                            <StandingsTeamTile key={key} name={TeamName} location={TeamLocation} wins={Wins} losses={Losses} ties={Ties} teamAbbrev={TeamAbbrev} />
                        ))}
                    </div>
                </Card>
            </div>
        );
    }

    attachToDB() {
        this.state.detach = attachEvent('Standings', (snapVal) => {
            var standings = {};
            if (this.state.division) {
                standings = this.filterStandings(snapVal.Division);
            }
            else {
                standings = this.filterStandings(snapVal.League);
            }

            cacheManager.saveStandings(this.state.conference, this.state.division, standings);

            this.setState({standings: standings, dbHit: true});
        });
    }

    detachFromDB() {
        if (this.state.detach) {
            this.state.detach();
            this.state.detach = null;
        }
    }

    componentDidMount() {
        this.attachToDB();
    }

    componentWillUnmount() {
        this.detachFromDB();
    }

    filterStandings(standings) {
        var tempStandings = {};
        if (this.state.conference) {
            var conf = this.state.conference.toLowerCase();
            tempStandings = standings.filter(function (team) {
                return team.ConferenceName.toLowerCase() === conf;
            });
            standings = tempStandings;
        }

        if (this.state.division) {
            tempStandings = {};
            var division = this.state.division.toLowerCase();
            tempStandings = standings.filter(function (team) {
                return team.DivisionName.toLowerCase() === division;
            });
            standings = tempStandings;
        }

        return standings;
    }
}
