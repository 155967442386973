import React, { useState } from "react";
import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom"
import { isTestMode } from '../../helpers/database.js';
import styled from "styled-components";
import "./navbar.css"

const Nav = styled.nav`
    padding: 0px; 
    height: 6rem;
    background: #0082e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    margin-top: -6.3rem;
    z-index: 999999;

    @media (max-width: 720px) {
        height: 4rem;
    }
`;

const Menu = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;

  li:nth-child(even) {
    margin: 0px 15px;
  }

  @media (max-width: 980px) {
    display: none;
  }
`;


const Item = styled.li``;

const NavIcon = styled.button`
    background: none;
    cursor: pointer;
    border: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    @media (min-width: 980px) {
        display: none;
    }
`;

const Line = styled.span`
    display: block;
    border-radius: 50px;
    width: 25px;
    height: 3px;
    margin: 5px;
    background-color: #fff;
    transition: width 0.4s ease-in-out;

    :nth-child(even) {
        width: ${(props) => (props.open ? "40%" : "70%")};
    }
`;

const Overlay = styled.div`
    position: fixed;
    height: ${(props) => (props.open ? "100vh" : 0)};
    width: 100%;
    background: #0082e6;
    transition: height 0.4s ease-in-out;
    margin-top: -11vw;
    z-index: 999998;

    @media (min-width: 980px) {
        display: none;
    }
`;

const OverlayMenu = styled.ul`
    list-style: none;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);

    li {
        opacity: ${(props) => (props.open ? 1 : 0)};
        font-size: 25px;
        margin: 10px 0px;
        transition: opacity 0.5s ease-in-out;
        transition-delay: ${(props) => (props.open ? "0.4s" : "0")};
    }

    li:nth-child(even) {
        margin: 10px 0px;
    }
`;

const Header = () => {
    const [isHamburgerMenuOpen, setHamburgerMenuVisible] = useState(false);
    const [linksVisible, setLinksVisible] = useState(false);

    const closeMobileMenu = () => {
        setLinksVisible(false);
        setHamburgerMenuVisible(false);
    }

    const hamburgerClicked = () => {
        if(!isHamburgerMenuOpen) setLinksVisible(false);
        setHamburgerMenuVisible(!isHamburgerMenuOpen);
    }

    const handleTransitionEnd = () => {
        if(isHamburgerMenuOpen) setLinksVisible(true);
    }

    var testModeDivClassName = isTestMode() ? "testMode" : "hide";
    return (
        <>
            <Nav>
                <a href='/' className="clickableLogo"><img className="logo" src={require('./logo.png')} alt='logo' /></a>
                <h1 className={testModeDivClassName}>RUNNING IN TEST MODE</h1>
                
                {/* DESKTOP NAVBAR MENU */}
                <Menu>
                    <CustomLink to="/" className="navlink" >UPCOMING GAMES</CustomLink>
                    <CustomLink to="/standings" className="navlink" >STANDINGS</CustomLink>
                    <CustomLink to="/streaks" className="navlink" >STREAKS</CustomLink>
                    <CustomLink to="/analytics/categories" className="navlink" >ANALYTICS</CustomLink>
                    <CustomLink to="/contact" className="navlink" >CONTACT US</CustomLink>
                    <a href='https://discord.gg/tVTQv5Yneg' target="_blank"><img className="socialLogo" src={require('./discord_logo.png')} alt='logo' /></a>
                </Menu>

                {/* HAMBURGER ICON */}
                <NavIcon onClick={hamburgerClicked}>
                    <Line open={isHamburgerMenuOpen} />
                    <Line open={isHamburgerMenuOpen} />
                    <Line open={isHamburgerMenuOpen} />
                </NavIcon>
            </Nav>

            {/* EXPANDED HAMBURGER MENU */}
            <Overlay open={isHamburgerMenuOpen} onTransitionEnd={handleTransitionEnd}>
                <OverlayMenu open={isHamburgerMenuOpen}>
                    {linksVisible && <CustomLink to="/" className="navlink" onClick={closeMobileMenu}>UPCOMING GAMES</CustomLink>}
                    {linksVisible && <CustomLink to="/standings" className="navlink" onClick={closeMobileMenu}>STANDINGS</CustomLink>}
                    {linksVisible && <CustomLink to="/streaks" className="navlink" onClick={closeMobileMenu}>STREAKS</CustomLink>}
                    {linksVisible && <CustomLink to="/analytics/categories" className="navlink" onClick={closeMobileMenu}>ANALYTICS</CustomLink>}
                    {linksVisible && <CustomLink to="/contact" className="navlink" onClick={closeMobileMenu}>CONTACT US</CustomLink>}
                    {linksVisible && <li><a href="https://discord.gg/tVTQv5Yneg" className="navlink" onClick={closeMobileMenu} target="_blank">JOIN US ON DISCORD!</a></li>}
                </OverlayMenu>
            </Overlay>
        </>
    );
};

export default Header;

function CustomLink({ to, children, ...props }) {
    const location = useLocation();
    const resolvedPath = useResolvedPath(to)

    const isActive = useMatch({ path: resolvedPath.pathname }) || (to.includes("/analytics") && location.pathname.includes("/analytics/"))

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
}