import { Card } from "@mui/material";
import colorManager from '../../../helpers/colors.js';
import logoManager from '../../../helpers/logos.js';
import teamsManager from '../../../helpers/teams.js';
import './analytics-team-tile.css';

export default function AnalyticsTeamTile(props) {
    const colors = colorManager.getTeamColors(props.name);
    const img = logoManager.getLogo(props.name.toLowerCase());
    var altScore = "";
    if (props.linkActive) altScore = props.altScore;

    if (props.linkActive) {
        return (
            <Card className="analytics-team-tile-card" sx={[
                {'&': {backgroundColor: colors.Primary, boxShadow: "0 8px 40px -12px rgba(0,0,0,0.6)", transition: "box-shadow 0ms"}}
                ]}>
                <div className="analytics-team-tile-logo-container">
                    <img src={img} alt="" className="analytics-team-tile-logo"/>
                </div>
                <h1 className="analytics-team-tile-name">{teamsManager.getTeamDisplayAbbrev(props.name)}</h1>
                <h1 className="analytics-team-tile-alt-score">{altScore}</h1>
                <h1 className="analytics-team-tile-main-score">{props.score}</h1>
            </Card>
        );
    }

    return(
        <Card className="analytics-team-tile-card" sx={{ backgroundColor: colors.Primary, boxShadow: "0 8px 40px -12px rgba(0,0,0,0.6)" }}>
            <div className="analytics-team-tile-logo-container">
                <img src={img} alt="" className="analytics-team-tile-logo"/>
            </div>
            <h1 className="analytics-team-tile-name">{teamsManager.getTeamDisplayAbbrev(props.name)}</h1>
            <h1 className="analytics-team-tile-alt-score">{altScore}</h1>
            <h1 className="analytics-team-tile-main-score">{props.score}</h1>
        </Card>
    );
}
