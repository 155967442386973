import React from 'react';
import { Button } from "@mui/material";
import './tag-filter.css';

export default class TagFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: props.tags || [],
            selectedIndex: props.tags.indexOf(props.selectedTag) || 0,
            isMenuOpen: false,
            windowWidth: window.innerWidth
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth });
    }

    toggleMenu = () => {
        this.setState((prevState) => ({
            isMenuOpen: !prevState.isMenuOpen,
        }));
    };

    closeMenu = () => {
        this.setState({ isMenuOpen: false });
    };

    handleClickOutside = (event) => {
        if (this.menuRef && !this.menuRef.contains(event.target)) {
            this.closeMenu();
        }
    };

    attachClickOutsideListener = () => {
        document.addEventListener("click", this.handleClickOutside);
    };

    detachClickOutsideListener = () => {
        document.removeEventListener("click", this.handleClickOutside);
    };

    handleSelection = (tag) => {
        const index = this.state.tags.indexOf(tag);
        this.setState({ selectedIndex: index });
        this.props.onChange(tag);
        this.closeMenu();
    }

    update = (tags, selectedTag) => {
        this.setState({ 
            tags: tags, 
            selectedIndex: tags.indexOf(selectedTag) 
        });
    }

    render() {
        const { selectedIndex, tags, isMenuOpen } = this.state;
        const selectedTag = tags[selectedIndex];

        if (this.state.windowWidth <= 979) { // Mobile view
            return (
                <div className="tag-filter-mobile-div-container">
                    <label className="tag-filter-label-name">CATEGORIES:</label>
                    <div className="tag-filter-mobile-dropdown" ref={(ref) => (this.menuRef = ref)}>
                        <button
                            className="tag-filter-dropdown-button"
                            onClick={() => {
                                if (!isMenuOpen) {
                                    this.attachClickOutsideListener();
                                } else {
                                    this.detachClickOutsideListener();
                                }
                                this.toggleMenu();
                            }}
                        >
                            <span className="tag-filter-button-text">{selectedTag || "Select an option"}</span>
                            <span className="tag-filter-button-icon">
                                <svg
                                    className={`tag-filter-dropdown-carrot ${isMenuOpen ? 'rotate' : ''}`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M6 9l6 6 6-6" />
                                </svg>
                            </span>
                        </button>
    
                        {isMenuOpen && (
                            <div className="tag-filter-dropdown-content">
                                {tags.map((tag, index) => (
                                    <div key={index} onClick={() => this.handleSelection(tag)}>
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        else { // Desktop view
            return (
                <div className="tag-filter-desktop-div-container">
                    {this.state.tags.map((tag, index) => (
                        <Button
                            key={index}
                            className={index === this.state.selectedIndex ? "selected" : ""}
                            style={{ fontSize: '2em' }}
                            onClick={() => this.handleSelection(tag)}
                        >
                            {tag}
                        </Button>
                    ))}
                </div>
            );
        }
    }
}
