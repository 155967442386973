import React from "react";
import './contact-view.css';
import '../general.css';
import Footer from '../../components/molecules/footer/footer.js';
import emailjs from 'emailjs-com';
import configManager from '../../helpers/config.js';

export default class ContactView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email:'',
            message: '',
            sent: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        if(this.state.message == "") return;

        console.log("sent");

        const templateParams = {
            message: this.state.message,
            email: this.state.email,
        };
    
        const serviceId = 'service_it84uik';
        const templateId = configManager.getConfigValue("EmailJsTemplateId");
        const userId = configManager.getConfigValue("EmailJsAPIKey");
      
        try {
            const result = await emailjs.send(serviceId, templateId, templateParams, userId);
            console.log('Email successfully sent!', result.text);
            this.setState({sent: true}); // update the state to indicate the email has been sent
        } catch(err) {
            console.error('Failed to send email.', err);
        }
    };

    render() {
        if (this.state.sent) {
            return (
                <div className="main">
                    <div className="haveAnIdeaContainer">
                        <h2>Thank you for reaching out! We appreciate all comments, questions, and concerns.</h2>
                    </div>
                    <Footer />
                </div>
            );
        } else {
            return (
                <div className="main">
                    <div className="haveAnIdeaContainer">
                        <h2>HAVE AN IDEA OR NEED TO CONTACT US?</h2>
                        <label>WE WOULD LOVE TO HEAR FROM YOU</label>
                        <input
                            type="text"
                            name="email"
                            placeholder="email (optional)"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                        <textarea
                            name="message"
                            placeholder="message"
                            value={this.state.message}
                            onChange={this.handleChange}
                        />
                        <button type="submit" onClick={this.handleSubmit}>SEND</button>
                    </div>
                    <Footer />
                </div>
            );
        }
    }
}
