import React from "react";
import { Routes, Route } from "react-router-dom";
import './home-view.css';
import '../general.css';
import UpcomingGames from '../../components/organisms/upcoming-games/upcoming-games.js';
import Footer from '../../components/molecules/footer/footer.js';
import ReactGA from "react-ga4";

export default class HomeView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="main">
                <div className="welcomeBlock">
                    <h2>Welcome Home!</h2>
                    <p>Here you can see various statistics and rankings of all NFL teams for the current season and dating back to 2014.</p>
                    <table className="helpMenu">
                        <tbody>
                            <tr className="helpMenuRow">
                                <td className="helpMenuPage">UPCOMING GAMES</td>
                                <td className="helpMenuPageDesc">View the upcoming games for a matchups comparison on key categories.</td>
                            </tr>
                            <tr className="helpMenuRow">
                                <td className="helpMenuPage">STANDINGS</td>
                                <td className="helpMenuPageDesc">View the current season's division, conference, and overall standings based on the season games.</td>
                            </tr>
                            <tr className="helpMenuRow">
                                <td className="helpMenuPage">STREAKS</td>
                                <td className="helpMenuPageDesc">View various active streaks held by every NFL team.</td>
                            </tr>
                            <tr className="helpMenuRow">
                                <td className="helpMenuPage">ANALYTICS</td>
                                <td className="helpMenuPageDesc">View various statistics about the NFL teams dating back to 2014.</td>
                            </tr>
                            <tr className="helpMenuRow">
                                <td className="helpMenuPage">CONTACT US</td>
                                <td className="helpMenuPageDesc">Want to reach out with a question? An idea? Or just to say hi? Email us here!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Routes>
                    <Route path=":id" element={
                        <UpcomingGames />
                    } />
                    <Route path="" element={
                        <UpcomingGames />
                    } />
                </Routes>
                <Footer />
            </div>
        );
    }
}
