class CacheManager {
    constructor() {
        this.standings = {};
        this.upcomingGames = {};
        this.streaks = [];
        this.timeLastUpdated = "Time last updated loading...";
    }

    getStandings(conference, division) {
        if (this.standings[conference] !== undefined) {
            return this.standings[conference][division];
        }
    
        return {};
    }
    
    saveStandings(conference, division, newStandings) {
        if (this.standings[conference] === undefined) this.standings[conference] = {};
        this.standings[conference][division] = newStandings;
    }
    
    getUpcomingGames() {
        return this.upcomingGames;
    }
    
    saveUpcomingGames(newUpcomingGames) {
        this.upcomingGames = newUpcomingGames;
    }
    
    getStreaks() {
        return this.streaks;
    }
    
    saveStreaks(newStreaks) {
        this.streaks = newStreaks;
    }
    
    getTimeLastUpdated(newTimeLastUpdated) {
        return this.timeLastUpdated;
    }
    
    saveTimeLastUpdated(newTimeLastUpdated) {
        this.timeLastUpdated = newTimeLastUpdated;
    }
}

const cacheManager = new CacheManager();
export default cacheManager;