import { React, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./views/home/home-view.js";
import StandingsPage from "./views/standings/standings-view.js";
import AnalyticsPage from "./views/analytics/analytics-view.js";
import ActiveStreaksPage from "./views/streaks/streaks-view.js";
import ContactPage from "./views/contact/contact-view.js";
import NotFoundPage from "./views/page-not-found/not-found-view.js";
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './views/navbar/navbar.js';
import { publishPageAnalytics } from './helpers/database.js'

// GLOBAL RUN ONCE ON STARTUP
function InitMethods() {
    useEffect(() => {
        console.log("App Starting...");
    }, []);

    return null;
}

// GLOBAL LOCATION CHANGE LISTENER
function LocationListener() {
    const location = useLocation();

    useEffect(() => {
        publishPageAnalytics();
        window.scrollTo(0, 0);
    }, [location]);

    return null;
}

export default function App() {
    return (
        <div>
            <InitMethods />
            <CssBaseline />
            <Router>
                <LocationListener />
                <Navbar />
                <Routes>
                    <Route path="*" element={<HomePage />} />
                    <Route path="/upcominggame/*" element={<HomePage />} />
                    <Route path="/standings" element={<StandingsPage />} />
                    <Route path="/analytics/*" element={<AnalyticsPage />} />
                    <Route path="/streaks" element={<ActiveStreaksPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Router>
        </div>
    );
}
