import React from 'react';
import logoManager from '../../../helpers/logos.js';
import teamsManager from '../../../helpers/teams.js';
import './team-filter.css'

var MaxTeams = 4;

export default class TeamFilter extends React.Component {
    constructor(props) {
        super(props);
        var teams = teamsManager.getDivisionSortedTeams();
        var logoRefs = [];
        for (var i = 0; i < teams.length; i++) {
            logoRefs.push(React.createRef());
        }

        this.state = {
            teams: teams,
            selectedTeams: [],
            onChange: props.onChange,
            logoRefs: logoRefs,
            windowWidth: window.innerWidth
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth });
    }

    update = (selectedTeams) => {
        for (var i = 0; i < this.state.teams.length; i++) {
            var ref = this.state.logoRefs[i];
            if (ref.current) {
                var teamName = this.state.teams[i];
                ref.current.update(selectedTeams.includes(teamName));
            }
        }

        this.state.selectedTeams = selectedTeams;
    }

    toggleTeam(teamName) {
        var newSelectedTeams = this.state.selectedTeams;
        if (newSelectedTeams.includes(teamName)) {
            newSelectedTeams.splice(newSelectedTeams.indexOf(teamName), 1);
        }
        else {
            newSelectedTeams.push(teamName);
        }
        
        if (newSelectedTeams.length > MaxTeams) {
            var ref = this.state.logoRefs[this.state.teams.indexOf(newSelectedTeams[0])];
            if (ref && ref.current) {
                ref.current.update(false);
            }
            newSelectedTeams = newSelectedTeams.slice(1);
        }

        var newRef = this.state.logoRefs[this.state.teams.indexOf(teamName)];
        if (newRef && newRef.current) {
            newRef.current.update(newSelectedTeams.includes(teamName));
        }

        this.state.onChange(newSelectedTeams);
        this.setState({ selectedTeams: newSelectedTeams });
    }

    render() {
        if (this.state.windowWidth <= 979) { // Mobile view
            return this.renderMobile();
        }
        else { // Desktop view
            return this.renderDesktop();
        }
    }

    renderDesktop() {
        // Display the AFC division
        var teamLogos = [];
        var locations = ["East", "North", "South", "West"];
        var locationCounter = 0;
        for (var i = 0; i < this.state.teams.length / 2; i += 4) {
            teamLogos.push(
                <div key={i} className="teamFilter-div-logoGroup">
                    <p className="teamFilter-p-divisionName">AFC {locations[locationCounter]}</p>
                    <TeamLogo ref={this.state.logoRefs[i]} key={i} name={this.state.teams[i]} selected={this.state.selectedTeams.includes(this.state.teams[i])} toggleTeam={(teamName) => this.toggleTeam(teamName)} />
                    <TeamLogo ref={this.state.logoRefs[i + 1]} key={i + 1} name={this.state.teams[i + 1]} selected={this.state.selectedTeams.includes(this.state.teams[i + 1])} toggleTeam={(teamName) => this.toggleTeam(teamName)} />
                    <TeamLogo ref={this.state.logoRefs[i + 2]} key={i + 2} name={this.state.teams[i + 2]} selected={this.state.selectedTeams.includes(this.state.teams[i + 2])} toggleTeam={(teamName) => this.toggleTeam(teamName)} />
                    <TeamLogo ref={this.state.logoRefs[i + 3]} key={i + 3} name={this.state.teams[i + 3]} selected={this.state.selectedTeams.includes(this.state.teams[i + 3])} toggleTeam={(teamName) => this.toggleTeam(teamName)} />
                </div>
            );
            locationCounter++;
        }

        // Display the NFC division
        var teamLogos2 = [];
        locationCounter = 0;
        for (i = this.state.teams.length / 2; i < this.state.teams.length; i += 4) {
            teamLogos2.push(
                <div key={i} className="teamFilter-div-logoGroup">
                    <p className="teamFilter-p-divisionName">NFC {locations[locationCounter]}</p>
                    <TeamLogo ref={this.state.logoRefs[i]} key={i} name={this.state.teams[i]} selected={this.state.selectedTeams.includes(this.state.teams[i])} toggleTeam={(teamName) => this.toggleTeam(teamName)} />
                    <TeamLogo ref={this.state.logoRefs[i + 1]} key={i + 1} name={this.state.teams[i + 1]} selected={this.state.selectedTeams.includes(this.state.teams[i + 1])} toggleTeam={(teamName) => this.toggleTeam(teamName)} />
                    <TeamLogo ref={this.state.logoRefs[i + 2]} key={i + 2} name={this.state.teams[i + 2]} selected={this.state.selectedTeams.includes(this.state.teams[i + 2])} toggleTeam={(teamName) => this.toggleTeam(teamName)} />
                    <TeamLogo ref={this.state.logoRefs[i + 3]} key={i + 3} name={this.state.teams[i + 3]} selected={this.state.selectedTeams.includes(this.state.teams[i + 3])} toggleTeam={(teamName) => this.toggleTeam(teamName)} />
                </div>
            );
            locationCounter++;
        }

        return (
            <div className="teamFilter-div-containerDesktop">
                <div className="teamFilter-div-logos prevent-select">
                    {teamLogos}
                </div>
                <div className="teamFilter-div-logos prevent-select">
                    {teamLogos2}
                </div>
            </div>
        );
    }

    renderMobile() {
        const { selectedTeams, teams, isMenuOpen } = this.state;
        const selectTeamsText =
            selectedTeams.length === 0
                ? "All"
                : selectedTeams.length === 1
                    ? `${selectedTeams.length} team`
                    : `${selectedTeams.length} teams`;

        const toggleMenu = () => {
            this.setState((prevState) => ({
                isMenuOpen: !prevState.isMenuOpen,
            }));
        };

        const closeMenu = () => {
            this.setState({ isMenuOpen: false });
        };

        const handleClickOutside = (event) => {
            if (this.menuRef && !this.menuRef.contains(event.target)) {
                closeMenu();
            }
        };

        const attachClickOutsideListener = () => {
            document.addEventListener("click", handleClickOutside);
        };

        const detachClickOutsideListener = () => {
            document.removeEventListener("click", handleClickOutside);
        };

        return (
            <div className="teamFilter-div-containerMobile">
                <label className="teamFilter-label-name">TEAMS:</label>
                <div
                    className="teamFilter-mobile-dropdown"
                    ref={(ref) => (this.menuRef = ref)}
                >
                    <button
                        className="teamFilter-dropdown-button"
                        onClick={() => {
                            if (!isMenuOpen) {
                                attachClickOutsideListener();
                            } else {
                                detachClickOutsideListener();
                            }
                            toggleMenu();
                        }}
                    >
                        <span className="teamFilter-button-text">{selectTeamsText}</span>
                        <span className="teamFilter-button-icon">
                            <svg
                                className={`teamFilter-dropdown-carrot ${isMenuOpen ? 'rotate' : ''}`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M6 9l6 6 6-6" />
                            </svg>
                        </span>
                    </button>

                    {isMenuOpen && (
                        <div className="teamFilter-dropdown-content">
                            {teams.map((team, index) => (
                                <label key={index}>
                                    <input
                                        type="checkbox"
                                        value={team}
                                        checked={selectedTeams.includes(team)}
                                        onChange={() => this.toggleTeam(team)}
                                    />
                                    {team}
                                </label>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

class TeamLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgSource: logoManager.getLogo(props.name.toLowerCase()),
            teamName: props.name,
            toggleTeam: props.toggleTeam,
            selected: props.selected
        };
    }

    update(selected) {
        this.setState({ selected: selected });
    }

    clicked = e => {
        this.state.toggleTeam(this.state.teamName);
    }

    render() {
        var className = "teamFilter-div-logo";
        if (this.state.selected) className += " teamFilter-div-logoSelected";
        return (
            <div className={className} onClick={this.clicked}>
                <img src={this.state.imgSource} alt="" className="teamFilter-img-logo" />
            </div>
        );
    }
}
