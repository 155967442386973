import React from 'react';
import { attachEvent } from '../../../helpers/database.js';
import colorManager from '../../../helpers/colors.js';
import teamsManager from '../../../helpers/teams.js';
import Chart from "react-apexcharts";
import "./team-radar.css";

// RadarView: 0
var radarCategoriesOverall = {
    "Average Rushing Yards Per Game":          {index: 0, name: "Rushing"},
    "Average Passing Yards Per Game":          {index: 1, name: "Passing"},
    "Average Points Allowed":         {index: 2, name: "Points Allowed"},
    "Average Rushing Yards Per Game Allowed":  {index: 3, name: "Rush Defense"},
    "Average Passing Yards Per Game Allowed":  {index: 4, name: "Pass Defense"},
    "Average Points Scored":          {index: 5, name: "Points Scored"},
}

// RadarView: 1
var radarCategoriesOffense = {
    "Average Points Scored":          {index: 0, name: "Points Scored"},
    "Average Rushing Yards Per Game":          {index: 1, name: "Rushing"},
    "Red Zone Offense":               {index: 2, name: "Red Zone"},
    "Average Turnovers":              {index: 3, name: "Turnovers"},
    "Average Sacks Allowed":          {index: 4, name: "Sacks"},
    "Average Passing Yards Per Game":          {index: 5, name: "Passing"},
}

// RadarView: 2
var radarCategoriesDefense = {
    "Average Points Allowed":         {index: 0, name: "Points Scored"},
    "Average Rushing Yards Per Game Allowed":  {index: 1, name: "Rushing"},
    "Red Zone Defense":               {index: 2, name: "Red Zone"},
    "Average Forced Turnovers":       {index: 3, name: "Turnovers"},
    "Average Sacks":                  {index: 4, name: "Sacks"},
    "Average Passing Yards Per Game Allowed":          {index: 5, name: "Passing"},
}

var radars = [radarCategoriesOverall, radarCategoriesOffense, radarCategoriesDefense];

var radarCatNames = {};
for (var i = 0; i < radars.length; i++) {
    var radar = radars[i];
    radarCatNames[i] = [];
    for (const [key, value] of Object.entries(radar)) {
        radarCatNames[i].push(value.name);
    }
}

export default class TeamRadar extends React.Component {
    constructor(props) {
        super(props);
        var teams = [];
        for (var i = 0; i < props.teams.length; i++) {
            var team = props.teams[i];
            teams.push(team.toUpperCase());
        }

        // chart documentation
        // https://apexcharts.com/docs/creating-first-javascript-chart/#

        this.state = {
            teams: teams,
            season: props.season,
            mode: props.mode,
            radarModes: props.radarModes,
            contrastToggle: props.contrastToggle,
            categoryRankings: [],
            options: {
                chart: {
                    id: "vsRadar",
                    toolbar: {
                        show: false,
                    },
                },
                xaxis: {
                    categories: radarCatNames[props.radarModes[0]]
                },
                yaxis: {
                    show: false,
                    max: 1
                },
                tooltip: {
                    enabled: true,
                },
                legend: {
                    position: "top"
                },
            },
            series: [{
                name: "-",
                data: []
            }]
        };
    }

    render() {
        var spacingClassName = this.props.spacing ? "-spacing" : "";
        return (
            <div className={"game" + spacingClassName}>
                <div className={"team-radar-div" + spacingClassName}>
                    <Chart className={"team-radar" + spacingClassName} options={this.state.options} series={this.state.series} type="radar" height='100%' width='100%'/>
                </div>
            </div>
        );
    }

    update = (teamAbbrevs, season, mode) => {
        this.detachFromDB();

        var teams = [];
        for (var i = 0; i < teamAbbrevs.length; i++) {
            var team = teamAbbrevs[i];
            teams.push(team.toUpperCase());
        }

        this.state.teams = teams;
        this.state.season = season;
        this.state.mode = mode;

        this.attachToDB();
    }

    attachToDB() {
        this.state.detach = attachEvent('Analytics/' + this.state.mode + '/' + this.state.season, (categories) => {
            var radarData = {};
            for (var i = 0; i < categories.length; i++) {
                var category = categories[i];
                var teamsInCategory = category.Rankings.map(function (c) {
                    return c.Name;
                });
                var ranking = -1;
                var preAdjustedValue = -1;
                for (var j = 0; j < this.state.teams.length; j++) {
                    var team = this.state.teams[j];
                    var radarCategories = this.state.radarModes.length > 1 ? radars[this.state.radarModes[j]] : radars[this.state.radarModes[0]];
                    if (teamsInCategory.includes(team)) {
                        ranking = teamsInCategory.indexOf(team) + 1;
                        if (category.Name in radarCategories) {
                            preAdjustedValue = (32 - ranking) / 31;
                            if (!radarData[team]) {
                                radarData[team] = [];
                            }

                            radarData[team][radarCategories[category.Name].index] = this.adjust(preAdjustedValue);
                        }
                    }
                }
            }

            var series = [];
            var outlineColors = [];
            var fillColors = [];
            for (var i = 0; i < this.state.teams.length; i++) {
                var team = this.state.teams[i];
                series.push({name: teamsManager.getTeamDisplayAbbrev(team), data: radarData[team]});

                var useTeamColors = (i == this.state.teams.length - 1) || !this.state.contrastToggle;
                if (useTeamColors) {
                    if (this.state.contrastToggle) {
                        outlineColors.push(colorManager.getBackgroundColor(team));
                        fillColors.push(colorManager.getContrastColor(team));
                    }
                    else {
                        outlineColors.push(colorManager.getTeamColors(team).Secondary);
                        fillColors.push(colorManager.getTeamColors(team).Primary);
                    }
                }
                else {
                    outlineColors.push(colorManager.getAwayColor());
                    fillColors.push(colorManager.getAwayColor());
                }
            }

            this.setState({series: series, options: this.constructOptions(outlineColors, fillColors)});
        });
    }

    detachFromDB() {
        if (this.state.detach) {
            this.state.detach();
            this.state.detach = null;
        }
    }

    adjust(ranking) {
        var adjustAmount = 0.9; // Must be in the range (0, 0.97]
        return ranking * adjustAmount + 1 - adjustAmount - 0.03;
    }

    constructOptions(outlineColors, fillColors) {
        var textColor = this.state.contrastToggle ? "white" : "black";
        return {
            chart: {
                id: "vsRadar",
                toolbar: {
                    show: false,
                }
            },
            xaxis: {
                categories: radarCatNames[this.state.radarModes[0]],
                labels: {
                    offsetY: 5,
                    style: {
                        // TODO make this default for all x-axis labels
                        colors: [textColor, textColor, textColor, textColor, textColor, textColor, textColor, textColor],
                        fontSize: '0.8em',
                        fontWeight: '700',
                    }
                }
            },
            yaxis: {
                show: false,
                max: 1
            },
            tooltip: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            fill: {
                colors: fillColors,
                opacity: 0.5,
                type: "solid"
            },
            colors: fillColors,
            stroke: {
                show: true,
                width: 3,
                colors: fillColors,
                dashArray: 0,
            },
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColors: ['#000000'],
                        connectorColors: '#e8e8e8',
                        strokeWidth: 2,
                        fill: {
                            colors: ['#e8e8e8']
                        }
                    }
                }
            }
        };
    }

    componentDidMount() {
        this.attachToDB();
    }

    componentWillUnmount() {
        this.detachFromDB();
    }
}
