import React from 'react';
import { Routes, Route } from "react-router-dom";
import AnalyticsCards from '../../components/organisms/analytics-cards/analytics-cards.js';
import SeasonFilter from '../../components/atoms/season-filter/season-filter.js';
import TagFilter from '../../components/atoms/tag-filter/tag-filter.js';
import TeamFilter from '../../components/atoms/team-filter/team-filter.js';
import Footer from '../../components/molecules/footer/footer.js';
import seasonsManager from '../../helpers/seasons.js';
import teamsManager from '../../helpers/teams.js';
import tagsManager from '../../helpers/tags.js';
import ReactGA from "react-ga4";
import './analytics-view.css';
import '../general.css';

export default class AnalyticsView extends React.Component {
    constructor(props) {
        super(props);
        this.analyticsRef = React.createRef();
        this.seasonFilterRef = React.createRef();
        this.tagFilterRef = React.createRef();
        this.teamFilterRef = React.createRef();
        var seasons = seasonsManager.getSeasons();
        var teams = teamsManager.getTeams();
        var tags = tagsManager.getTags();
        this.state = {
            seasonMode: true,
            seasons: seasons,
            tags: tags,
            selectedTag: tags[0],
            selectedTeams: [],
            latestSeason: seasons[seasons.length-1],
            season: seasons[seasons.length-1],
            teams: teams,
            team: teams[0],
            showHideFilters: true,
            showHideFiltersButtonText: "HIDE FILTERS"
        };

        seasonsManager.onSeasonsUpdated(this.updateSeasonsList.bind(this));
        tagsManager.onTagsUpdated(this.updateTagsList.bind(this));
    }

    updateMode = (season, seasonMode) => {
        if (this.analyticsRef.current) {
            this.analyticsRef.current.update(season, seasonMode, this.state.selectedTag, this.state.selectedTeams);
        }

        this.setState({season: season, seasonMode: seasonMode});
    }

    updateTeam = e => {
        this.setState({team: e.target.value});
    }

    updateSelectedTeams = (selectedTeams) => {
        if (this.analyticsRef.current) {
            this.analyticsRef.current.update(this.state.season, this.state.seasonMode, this.state.selectedTag, selectedTeams);
        }

        this.state.selectedTeams = selectedTeams;
        this.setState({selectedTeams: selectedTeams});
    }

    updateTag = (tag) => {
        if (this.analyticsRef.current) {
            this.analyticsRef.current.update(this.state.season, this.state.seasonMode, tag, this.state.selectedTeams);
        }

        this.setState({selectedTag: tag});
    }

    updateSeasonsList() {
        var seasons = seasonsManager.getSeasons();
        if (!seasons.includes(this.state.season)) {
            this.state.season = seasons[seasons.length-1];
            this.analyticsRef.current.update(this.state.season, this.state.seasonMode, this.state.selectedTag, this.state.selectedTeams);
        }

        if (this.seasonFilterRef.current) {
            this.seasonFilterRef.current.update(seasons, this.state.season);
        }

        this.setState({seasons: seasons, latestSeason: this.state.latestSeason});
    }

    updateTagsList() {
        var tags = tagsManager.getTags();
        if (!tags.includes(this.state.selectedTag)) {
            this.state.selectedTag = tags[0];
            this.analyticsRef.current.update(this.state.season, this.state.seasonMode, this.state.selectedTag, this.state.selectedTeams);
        }

        if (this.tagFilterRef.current) {
            this.tagFilterRef.current.update(tags, this.state.selectedTag);
        }

        this.setState({tags: tags});
    }

    componentDidMount() {
        if (this.teamFilterRef.current) {
            this.teamFilterRef.current.update(this.state.selectedTeams);
        }
    }

    onClickShowHideFilters = e => {
        this.setState({showHideFilters: !this.state.showHideFilters})
        if (this.state.showHideFilters) {
            this.setState({showHideFiltersButtonText: "SHOW FILTERS"})
        }
        else {
            this.setState({showHideFiltersButtonText: "HIDE FILTERS"})
        }
    }

    render() {
        var mode = this.state.seasonMode ? "Season" : "Cumulative";
        var optionsClassName = this.state.showHideFilters ? "analytics-options" : "hide";
        
        // Add this above the options div if we want this back in
        //<button onClick={this.onClickShowHideFilters} className="analyticsView-button-showHideFilters">{this.state.showHideFiltersButtonText}</button>
        return (
            <div className="main">
                <Routes>
                    <Route path="categories/:teams" element={
                        <div>
                            <div className={optionsClassName}>
                                <TeamFilter ref={this.teamFilterRef} onChange={this.updateSelectedTeams} />
                                <TagFilter ref={this.tagFilterRef} tags={this.state.tags} selectedTag={this.state.selectedTag} onChange={this.updateTag} />
                                <SeasonFilter ref={this.seasonFilterRef} seasons={this.state.seasons} selectedSeason={this.state.season} seasonMode={this.state.seasonMode} onChange={this.updateMode} />
                            </div>
                            <AnalyticsCards
                                forwardedRef={this.analyticsRef}
                                className="main"
                                season={this.state.season}
                                mode={mode}
                                latestSeason={this.state.latestSeason}
                                tag={this.state.selectedTag}
                                selectedTeams={this.state.selectedTeams}
                                updateSelectedTeams={this.updateSelectedTeams}
                            />
                        </div>
                    } />
                    <Route path="categories/" element={
                        <div>
                            <div className={optionsClassName}>
                                <TeamFilter ref={this.teamFilterRef} onChange={this.updateSelectedTeams} />
                                <TagFilter ref={this.tagFilterRef} tags={this.state.tags} selectedTag={this.state.selectedTag} onChange={this.updateTag} />
                                <SeasonFilter ref={this.seasonFilterRef} seasons={this.state.seasons} selectedSeason={this.state.season} seasonMode={this.state.seasonMode} onChange={this.updateMode} />
                            </div>
                            <AnalyticsCards
                                forwardedRef={this.analyticsRef}
                                className="main"
                                season={this.state.season}
                                mode={mode}
                                latestSeason={this.state.latestSeason}
                                tag={this.state.selectedTag}
                                selectedTeams={this.state.selectedTeams}
                                updateSelectedTeams={this.updateSelectedTeams}
                            />
                        </div>
                    } />
                    <Route path="category/:id" element={
                        <div>
                            <div className={optionsClassName}>
                                <TeamFilter ref={this.teamFilterRef} onChange={this.updateSelectedTeams} />
                                <SeasonFilter ref={this.seasonFilterRef} seasons={this.state.seasons} selectedSeason={this.state.season} seasonMode={this.state.seasonMode} onChange={this.updateMode} />
                            </div>
                            <AnalyticsCards
                                key={1}
                                forwardedRef={this.analyticsRef}
                                className="main"
                                season={this.state.season}
                                mode={mode}
                                latestSeason={this.state.latestSeason}
                                tag={this.state.selectedTag}
                                selectedTeams={this.state.selectedTeams}
                                updateSelectedTeams={this.updateSelectedTeams}
                            />
                        </div>
                    } />
                    <Route path="category/:id/:teams/:season/:mode" element={
                        <AnalyticsCards
                            key={1}
                            forwardedRef={this.analyticsRef}
                            className="main"
                            season={this.state.season}
                            mode={mode}
                            latestSeason={this.state.latestSeason}
                            tag={this.state.selectedTag}
                            selectedTeams={this.state.selectedTeams}
                            updateSelectedTeams={this.updateSelectedTeams}
                        />
                    } />
                </Routes>
                <Footer />
            </div>
        );
    }
}
