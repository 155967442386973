import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { isTestMode } from './database.js';

const firebaseConfig = {
    apiKey: "AIzaSyCPz4ntBQEnIS2kw_CGzOb3SjF2x4BTVnM",
    authDomain: "nlf---config.firebaseapp.com",
    databaseURL: "https://nlf---config-default-rtdb.firebaseio.com",
    projectId: "nlf---config",
    storageBucket: "nlf---config.appspot.com",
    messagingSenderId: "619883382224",
    appId: "1:619883382224:web:75e3f01db5a3e4c901e5d5",
    measurementId: "G-14Y888NXRJ"
};

const testPath = "WebTestConfig";
const livePath = "WebLiveConfig";

class ConfigManager {
    constructor() {
        this.firebaseApp = initializeApp(firebaseConfig);
        this.db = getDatabase(this.firebaseApp);
        this.configs = {};
        this.listener = null;

        this.#initConfig();
    }

    getConfigValue(configName) {
        if (configName in this.configs) {
            return this.configs[configName];
        }

        return null;
    }

    #initConfig() {
        const dbRef = ref(this.db, isTestMode() ? testPath : livePath);
        this.listener = onValue(dbRef, (snapshot) => {
            if (snapshot.exists()) {
                this.configs = snapshot.val();
            }
        });
    }
}

const configManager = new ConfigManager();
export default configManager;