import { Card } from "@mui/material";
import colorManager from '../../../helpers/colors.js';
import logoManager from '../../../helpers/logos.js';
import teamsManager from '../../../helpers/teams.js';
import './streak-team-tile.css';

export default function StreakTeamTile(props) {
    const colors = colorManager.getTeamColors(props.name);
    const img = logoManager.getLogo(props.name.toLowerCase());

    return (
        <Card className="streak-team-tile-card prevent-select" sx={[
            {'&': {backgroundColor: colors.Primary, boxShadow: "0 8px 40px -12px rgba(0,0,0,0.6)", transition: "box-shadow 0ms"}}
            ]}>
            <div className="streak-team-tile-logo-container">
                <img src={img} alt="" className="streak-team-tile-logo"/>
            </div>
            <h1 className="streak-team-tile-name">{teamsManager.getTeamDisplayAbbrev(props.name)}</h1>
            <h1 className="streak-team-tile-score">{props.streakString}</h1>
        </Card>
    );
}
