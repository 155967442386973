import { attachEvent } from './database.js';

var today = new Date();
var startYear = today.getFullYear();
if (today.getMonth() < 8) startYear--;

var seasons = [`${startYear}`];
var callbacks = [];

var useTeamLogos = true;

class SeasonsManager {
    constructor() {
        this.seasons = [`${startYear}`];
        this.callbacks = [];

        attachEvent('Analytics/Season', (analyticsSeasons) => {
            var temp = Object.keys(analyticsSeasons);
            if (temp.length > 0) {
                seasons = temp;
                for (var i = 0; i < callbacks.length; i++) {
                    callbacks[i]();
                }
            }
        });

    }
    
    getSeasons() {
        return seasons;
    }
    
    onSeasonsUpdated(callback) {
        callbacks.push(callback);
    }
    
}

const seasonsManager = new SeasonsManager();
export default seasonsManager;

