import React from 'react';
import { Button } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import './season-filter.css'

export default class SeasonFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seasons: props.seasons,
            selectedIndex: props.seasons.indexOf(props.selectedSeason),
            mode: props.seasonMode,
            onChange: props.onChange
        };
    }

    update = (seasons, selectedSeason) => {
        this.setState({seasons: seasons, selectedIndex: seasons.indexOf(selectedSeason)});
    }

    decrementSeason = e => {
        var newIndex = this.state.selectedIndex-1;
        if (this.state.selectedIndex > 0) {
            this.setState({selectedIndex: newIndex});
            this.state.onChange(this.state.seasons[newIndex], this.state.mode);
        }
    }

    incrementSeason = e => {
        var newIndex = this.state.selectedIndex+1;
        if (newIndex < this.state.seasons.length ) {
            this.setState({selectedIndex: newIndex});
            this.state.onChange(this.state.seasons[newIndex], this.state.mode);
        }
    }

    toggleMode = e => {
        var newMode = !this.state.mode;
        this.setState({mode: newMode});
        this.state.onChange(this.state.seasons[this.state.selectedIndex], newMode);
    }

    render() {
        var seasonDisplay = this.state.seasons[this.state.selectedIndex];
        if (!this.state.mode) seasonDisplay += " - " + this.state.seasons[this.state.seasons.length-1];
        return (
            <div className="seasonFilter-div-container">
                <a><FontAwesomeIcon className="seasonFilter-fontAwesomeIcon-caret" onClick={this.decrementSeason} icon={faCaretLeft} /></a>
                <Button style={{ fontSize: '2em' }} onClick={this.toggleMode}>{seasonDisplay}</Button>
                <FontAwesomeIcon className="seasonFilter-fontAwesomeIcon-caret" onClick={this.incrementSeason} icon={faCaretRight} />
            </div>
        );
    }
}
