import { attachEvent } from '../../../helpers/database.js';
import { reattachIfFailed } from '../../../helpers/reattacher.js';
import seasonsManager from '../../../helpers/seasons.js';
import { Link, useParams } from "react-router-dom";
import AnalyticsCard from '../../molecules/analytics-card/analytics-card.js'
import TeamRadar from '../../atoms/team-radar/team-radar.js';
import React from "react";
import './analytics-cards.css'

class AnalyticsCards extends React.Component {
    constructor(props) {
        super(props);
        this.radarRef = React.createRef();
        var selectedTeams = props.selectedTeams;
        if (props.params.teams) {
            selectedTeams = props.params.teams.toUpperCase().split('-');
            window.scroll(0, 0);
            props.updateSelectedTeams(selectedTeams);
        }

        var season = props.season;
        var mode = props.mode;
        if (props.params.season && props.params.mode) {
            season = props.params.season;
            var seasons = seasonsManager.getSeasons();
            var lastSeason = seasons[seasons.length - 1];
            if (season > lastSeason) season = lastSeason;
            mode = props.params.mode == 0 ? "Season" : "Cumulative";
        }

        var customMode = props.params.id &&
            props.params.teams &&
            props.params.season &&
            props.params.mode;

        reattachIfFailed(this);

        this.state = {
            mode: mode,
            season: season,
            tag: props.tag,
            latestSeason: props.latestSeason,
            quantity: selectedTeams.length === 0 ? 3 : selectedTeams.length,
            categoryId: props.params.id,
            categories: {},
            oldRankings: [],
            selectedTeams: selectedTeams,
            customMode: customMode
        }

        if (this.state.categoryId) {
            this.state.quantity = 32;
        }
    }

    update = (season, seasonMode, tag, selectedTeams) => {
        this.detachFromDB();
        this.state.mode = seasonMode ? "Season" : "Cumulative";
        this.state.season = season;
        this.state.tag = tag;
        this.state.selectedTeams = selectedTeams;
        if (selectedTeams.length === 0) {
            if (this.state.categoryId) {
                this.state.quantity = 32;
            }
            else {
                this.state.quantity = 3;
            }
        }
        else {
            this.state.quantity = selectedTeams.length;
        }

        if (this.radarRef && this.radarRef.current) {
            this.radarRef.current.update(selectedTeams, season, this.state.mode);
        }

        this.attachToDB();
    }

    attachToDB() {
        this.state.detach = attachEvent('Analytics/' + this.state.mode + '/' + this.state.season, (categories) => {
            if (this.state.categoryId && this.state.categoryId >= 0 && this.state.categoryId < categories.length) {
                categories = [categories[this.state.categoryId]];
            }

            for (var i = 0; i < categories.length; i++) {
                var category = categories[i];
                var newRankings = [];
                for (var j = 0; j < category.Rankings.length; j++) {
                    var ranking = category.Rankings[j];
                    if (this.state.selectedTeams.length === 0 || this.state.selectedTeams.includes(ranking.Name)) {
                        newRankings.push(ranking);
                    }
                }
                category.Rankings = newRankings;
            }

            this.setState({categories: categories, dbHit: true});
        });

        this.state.detach2 = attachEvent('Analytics/Change', (categories) => {
            this.setState({oldRankings: categories});
        });
    }

    detachFromDB() {
        if (this.state.detach) {
            this.state.detach();
            this.state.detach = null;
        }

        if (this.state.detach2) {
            this.state.detach2();
            this.state.detach2 = null;
            this.state.oldRankings = [];
        }
    }

    render() {
        var categories = [];
        var singleCategory = this.state.categories.length === 1;
        var divClassName = "multipleCategories";
        if (singleCategory) divClassName = "singleCategory";
        var linkClassName = "analyticsCards-link-" + divClassName;
        if (this.state.customMode) divClassName += " customMode";
        var showRankings = singleCategory || this.state.selectedTeams.length > 0;
        for (var i = 0; i < this.state.categories.length; i++) {
            var oldRankings = [];
            if (this.state.season === this.state.latestSeason && this.state.mode === "Season" && this.state.oldRankings[i]) oldRankings = this.state.oldRankings[i].Rankings;
            var category = this.state.categories[i];
            var link = "";
            if (singleCategory) {
                link = "../categories";
            }
            else {
                link = "../category/" + i;
            }

            if (singleCategory || category.Tags.includes(this.state.tag)) {
                categories.push(
                    <Link to={link} key={i} className={linkClassName}>
                        <div className={divClassName}>
                            <AnalyticsCard
                                className="cardCategory"
                                categoryId={i}
                                categoryName={category.Name}
                                rankings={category.Rankings}
                                oldRankings={oldRankings}
                                quantity={this.state.quantity}
                                showLink={!singleCategory && this.state.selectedTeams.length === 0}
                                showRankings={showRankings}
                                customMode={this.state.customMode} />
                        </div>
                    </Link>
                );
            }
        }

        var radar = null;
        if (this.state.selectedTeams.length > 0 && this.state.selectedTeams.length <= 2 && !singleCategory) {
            radar = (<TeamRadar
                ref={this.radarRef}
                contrastToggle={false}
                teams={this.state.selectedTeams}
                radarModes={[0]}
                season={this.state.season}
                mode="Season" />);
        }

        return (
            <div>
                <div className="teamRadar">
                    {radar}
                </div>
                <div className="categoryHolderDiv">
                    {categories}
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.attachToDB();
    }

    componentWillUnmount() {
        this.detachFromDB();
    }
}

export default function AnalyticsComponentWrapper(props) {
    const params = useParams();
    return <AnalyticsCards {...props} params={params} ref={props.forwardedRef}/>;
};
