var teamColors = {
    "NE": {
        "Primary": "#002244",
        "Secondary": "#C60C30"
    },
    "DAL": {
        "Primary": "#002244",
        "Secondary": "#869397"
    },
    "ARZ": {
        "Primary": "#97233F",
        "Secondary": "#000000"
    },
    "SF": {
        "Primary": "#aa0000",
        "Secondary": "#b3995d"
    },
    "MIN": {
        "Primary": "#4f2683",
        "Secondary": "#ffc62f"
    },
    "BUF": {
        "Primary": "#00338d",
        "Secondary": "#c60c30"
    },
    "ATL": {
        "Primary": "#a71930",
        "Secondary": "#000000"
    },
    "BLT": {
        "Primary": "#241773",
        "Secondary": "#000000"
    },
    "CAR": {
        "Primary": "#0085ca",
        "Secondary": "#000000"
    },
    "CHI": {
        "Primary": "#0b162a",
        "Secondary": "#c83803"
    },
    "CIN": {
        "Primary": "#fb4f14",
        "Secondary": "#000000"
    },
    "CLV": {
        "Primary": "#311d00",
        "Secondary": "#ff3c00"
    },
    "DEN": {
        "Primary": "#fb4f14",
        "Secondary": "#002244"
    },
    "DET": {
        "Primary": "#0076b6",
        "Secondary": "#b0b7bc"
    },
    "GB": {
        "Primary": "#203731",
        "Secondary": "#ffb612"
    },
    "HST": {
        "Primary": "#03202f",
        "Secondary": "#a71930"
    },
    "IND": {
        "Primary": "#002c5f",
        "Secondary": "#a5acaf"
    },
    "JAX": {
        "Primary": "#006778",
        "Secondary": "#000000"
    },
    "KC": {
        "Primary": "#e31837",
        "Secondary": "#ffb612"
    },
    "LAC": {
        "Primary": "#0073cf",
        "Secondary": "#ffb612"
    },
    "LAR": {
        "Primary": "#002244",
        "Secondary": "#b3995d"
    },
    "MIA": {
        "Primary": "#008e97",
        "Secondary": "#f26a24"
    },
    "NO": {
        "Primary": "#d3bc8d",
        "Secondary": "#000000"
    },
    "NYG": {
        "Primary": "#0b2265",
        "Secondary": "#a71930"
    },
    "NYJ": {
        "Primary": "#003f2d",
        "Secondary": "#000000"
    },
    "LV": {
        "Primary": "#a5acaf",
        "Secondary": "#000000"
    },
    "PHI": {
        "Primary": "#004c54",
        "Secondary": "#a5acaf"
    },
    "PIT": {
        "Primary": "#000000",
        "Secondary": "#ffb612"
    },
    "SEA": {
        "Primary": "#002244",
        "Secondary": "#69be28"
    },
    "TB": {
        "Primary": "#d50a0a",
        "Secondary": "#34302b"
    },
    "TEN": {
        "Primary": "#002244",
        "Secondary": "#4b92db"
    },
    "WAS": {
        "Primary": "#5A1414",
        "Secondary": "#ffb612"
    }
};

class ColorManager {
    constructor() {
        this.standings = {};
        this.upcomingGames = {};
        this.streaks = [];
        this.timeLastUpdated = "Time last updated loading...";
    }

    getTeamColors(teamAbbrev) {
        teamAbbrev = teamAbbrev.toUpperCase();
        if (teamColors[teamAbbrev]) {
            return teamColors[teamAbbrev];
        }
    
        var def = {Primary: '#FF0000', Secondary: '#FFFFFF'}
        return def;
    }
    
    getContrastColor(teamAbbrev) {
        var teamColors = this.getTeamColors(teamAbbrev);
    
        if (teamAbbrev.toUpperCase() === "LV") return teamColors.Secondary;
    
        return teamColors.Primary;
    }
    
    getBackgroundColor(teamAbbrev) {
        var teamColors = this.getTeamColors(teamAbbrev);
    
        if (teamAbbrev.toUpperCase() === "LV") return teamColors.Primary;
    
        return teamColors.Secondary;
    }
    
    getAwayColor() {
        return "#AAAAAA";
    }
}

const colorManager = new ColorManager();
export default colorManager;