import React from 'react';
import StandingsCard from '../../molecules/standings-card/standings-card';
import './standings-cards.css';

export default class StandingsCards extends React.Component {
    render() {
        return (
            <div>
                <div className={this.props.active === 1 ? "division-standings" : "division-standings hidden"}>
                    <div className="afc-divisions">
                        <StandingsCard conference="afc" division="east"/>
                        <StandingsCard conference="afc" division="north"/>
                        <StandingsCard conference="afc" division="south"/>
                        <StandingsCard conference="afc" division="west"/>
                    </div>
                    <div className="nfc-divisions">
                        <StandingsCard conference="nfc" division="east"/>
                        <StandingsCard conference="nfc" division="north"/>
                        <StandingsCard conference="nfc" division="south"/>
                        <StandingsCard conference="nfc" division="west"/>
                    </div>
                </div>
                <div className={this.props.active === 2 ? "conference-standings" : "conference-standings hidden"}>
                    <div className="afc-conference">
                        <StandingsCard conference="afc" />
                    </div>
                    <div className="nfc-conference">
                        <StandingsCard conference="nfc" />
                    </div>
                </div>
                <div className={this.props.active === 3 ? "league-standings" : "league-standings hidden"}>
                    <StandingsCard />
                </div>
            </div>
        );
    }
}