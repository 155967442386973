import configManager from './config.js';

var teamLogosFolder = "realTeamLogos";
var fakeLogosFolder = "fakeTeamLogos";

var useTeamLogos = true;

class LogoManager {
    getLogo(teamAbbrev) {
        //var logoFolder = (configManager.getConfigValue("UseRealLogos") ?? false) ? teamLogosFolder : fakeLogosFolder;
        var logoFolder = useTeamLogos ? teamLogosFolder : fakeLogosFolder;
        return require("../../res/imgs/" + logoFolder + "/" + teamAbbrev + ".png");
    }
}

const logoManager = new LogoManager();
export default logoManager;
