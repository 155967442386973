import React from "react";
import "./standings-filter.css";

export default class StandingFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.active,
            action: this.props.action
        };
    }

    changeButton1( ) {
        this.setState({active: 1});
        this.state.action(1);
    }

    changeButton2( ) {
        this.setState({active: 2});
        this.state.action(2);
    }

    changeButton3( ) {
        this.setState({active: 3});
        this.state.action(3);
    }

    render() {
        return (
            <div className="standingsFilter-div-container">
                <StandingToggle active={this.state.active === 1} text="DIVISION" onClick={ this.changeButton1.bind(this) }  />
                <StandingToggle active={this.state.active === 2} text="CONFERENCE" onClick={ this.changeButton2.bind(this) } />
                <StandingToggle active={this.state.active === 3} text="LEAGUE" onClick={ this.changeButton3.bind(this) } />
            </div>
        );
    }
}

function StandingToggle(props) {
    return (
        <button className={props.active ? "standingsFilter-button-toggle active" : "standingsFilter-button-toggle"} onClick={props.onClick}>{props.text}</button>
    );
}
