import React from "react";
import { Card } from "@mui/material";
import AnalyticsTeamTile from '../../atoms/analytics-team-tile/analytics-team-tile.js';
import colorManager from '../../../helpers/colors.js';
import "./analytics-card.css";

export default function AnalyticsCard(props) {
    var color = colorManager.getTeamColors(props.rankings[0].Name);
    var rankingDivClassName = "ranking";
    var displayRankings = props.rankings.slice(0, props.quantity);
    if (!props.showRankings) rankingDivClassName += " hide";
    var rankings = [];
    for (var i = 0; i < displayRankings.length; i++) {
        var ranking = displayRankings[i];
        var oldTeamRanking = props.oldRankings.map(function(r) { return r.Name; }).indexOf(ranking.Name)+1;
        var teamColor = colorManager.getTeamColors(ranking.Name).Primary;
        var border = "0.5em solid ";
        var upArrowBorderBottom = border + (ranking.Ranking < oldTeamRanking && props.oldRankings.length > 0 ? "#00BB00" : "#FFFFFF");
        var downArrowBorderTop = border + (ranking.Ranking > oldTeamRanking && props.oldRankings.length > 0 ? "#DD0000" : "#FFFFFF");
        rankings.push(
            <div key={i} className="teamContainer">
                <div className={rankingDivClassName}>
                    <div className="upArrow" style={{ borderBottom: upArrowBorderBottom }} />
                    <div className="rankingCircle" style={{ backgroundColor: teamColor }}>
                        <label className="rankingNumber">{ranking.Ranking}</label>
                    </div>
                    <div className="downArrow" style={{ borderTop: downArrowBorderTop }} />
                </div>
                <AnalyticsTeamTile name={ranking.Name} score={ranking.Score} altScore={ranking.AltScore} linkActive={props.showRankings} displayAltScore={props.displayAltScore}/>
            </div>
        );
    }

    return (
        <Card
            className="categoryContainer"
            sx={{
            '&': {
                boxShadow: props.customMode ? "0 5px 10px 0px rgba(0,0,0,0.3)" : "0 8px 40px -12px rgba(0,0,0,0.3)",
                transition: "box-shadow 300ms"
            },
            [`@media screen and (min-width: 720px)`]: {
                '&:hover': {
                boxShadow: props.customMode ? "none" : "0 0 3px 10px " + color.Primary + ", 0 0 5px 15px " + color.Secondary
                }
            }
            }}>
            <h2 className="categoryHeader">{props.categoryName}</h2>
            <div>
            {rankings}
            </div>
        </Card>
      );
}
