import { attachEvent } from '../../../helpers/database.js';
import cacheManager from '../../../helpers/cacher.js'
import { reattachIfFailed } from '../../../helpers/reattacher.js';
import StreakCard from '../../molecules/streak-card/streak-card.js'
import React from "react";
import './streak-cards.css'

export default class StreakCards extends React.Component {
    constructor(props) {
        super(props);
        window.scroll(0, 0);

        reattachIfFailed(this);

        this.state = {
            dbEvent: false,
            streaks: cacheManager.getStreaks(),
            selectedTeams: props.selectedTeams
        }
    }

    update = (selectedTeams) => {
        this.detachFromDB();
        this.state.selectedTeams = selectedTeams;
        this.attachToDB();
    }

    attachToDB() {
        this.state.detach = attachEvent('Streaks', (streaksMain) => {
            this.setState({dbEvent: true});
            var streaks = streaksMain.Categories;
            if (streaksMain.Categories == null) {
                return;
            }
            var filteredStreaks = [];
            if (this.state.selectedTeams.length === 0) {
                filteredStreaks = streaks;
                cacheManager.saveStreaks(filteredStreaks);
            }
            else {
                for (var i = 0; i < streaks.length; i++) {
                    var streak = streaks[i];
                    var filteredStreakEntries = [];
                    for (var j = 0; j < streak.StreakEntries.length; j++) {
                        var streakEntry = streak.StreakEntries[j];
                        if (this.state.selectedTeams.includes(streakEntry.Name)) {
                            filteredStreakEntries.push(streakEntry);
                        }
                    }

                    if (filteredStreakEntries.length > 0) {
                        filteredStreaks.push({Name: streak.Name, StreakEntries: filteredStreakEntries});
                    }
                }
            }

            this.setState({streaks: filteredStreaks, dbHit: true});
        });
    }

    detachFromDB() {
        if (this.state.detach) {
            this.state.detach();
            this.state.detach = null;
        }
    }

    render() {
        var streaks = [];
        for (var i = 0; i < this.state.streaks.length; i++) {
            var streak = this.state.streaks[i];
            streaks.push(
                <div className="streak-cards-card-container" key={i}>
                    <StreakCard
                        streakName={streak.Name}
                        streakEntries={streak.StreakEntries} />
                </div>
            );
        }

        var toDisplay = streaks;

        if (streaks.length === 0) {
            if (this.state.dbEvent) {
                toDisplay = <p className="streakCards-p-message">No current streaks, check back later!</p>;
            }
            else {
                toDisplay = <p className="streakCards-p-message">Loading streaks...</p>;
            }
        }

        return (
            <div className="streak-cards-container">
                {toDisplay}
            </div>
        );
    }

    componentDidMount() {
        this.attachToDB();
    }

    componentWillUnmount() {
        this.detachFromDB();
    }
}
