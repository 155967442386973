import StandingsCards from '../../components/organisms/standings-cards/standings-cards.js';
import StandingsFilter from '../../components/atoms/standings-filter/standings-filter.js';
import Footer from '../../components/molecules/footer/footer.js';
import React from "react";
import './standings-view.css';
import '../general.css';

export default class StandingsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 1
        };
    }

    showStanding(id) {
        this.setState({active: id});
    }

    render() {
        return (
            <div className="main">
                <StandingsFilter active={this.state.active} action={this.showStanding.bind(this)} />
                <StandingsCards active={this.state.active} />
                <Footer />
            </div>
        );
    }
}
