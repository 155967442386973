var version = "1.2.10";

class VersionManager {

      getVersion() {
            return version;
      }

      getVersionString() {
            return "NFL Stat University v" + version;
      }

}

const versionManager = new VersionManager();
export default versionManager;
