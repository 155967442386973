import React from 'react';
import StreakCards from '../../components/organisms/streak-cards/streak-cards.js';
import TeamFilter from '../../components/atoms/team-filter/team-filter.js';
import Footer from '../../components/molecules/footer/footer.js';
import ReactGA from "react-ga4";
import './streaks-view.css';

export default class ActiveStreaksView extends React.Component {
    constructor(props) {
        super(props);
        this.streaksRef = React.createRef();
        this.state = {
            selectedTeams: []
        };
    }

    updateSelectedTeams = (selectedTeams) => {
        if (this.streaksRef.current) {
            this.streaksRef.current.update(selectedTeams);
        }

        this.setState({selectedTeams: selectedTeams});
    }

    render() {
        return (
            <div className="main">
                <TeamFilter onChange={this.updateSelectedTeams} />
                <StreakCards ref={this.streaksRef} selectedTeams={this.state.selectedTeams} />
                <Footer />
            </div>
        );
    }
}
