import versionManager from '../../../helpers/version.js';
import './version-number.css';

export default function VersionNumber(props) {
    return (
        <div className="version-number">
            <p>{versionManager.getVersionString()}</p>
        </div>
    );
}
